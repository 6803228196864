<form autocomplete="off">
  <input
    *appIfIsDesktop
    ngbAutofocus
    [ngbTypeahead]="searchCity"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-desktop bg-transparent'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    type="text"
    class="form-control"
    placeholder="       City or Address"
    autocomplete="new-search-city"
    (selectItem)="selectItem($event)"
    (input)="onInput($event.target)"
  >

  <input
    *appIfIsMobile
    ngbAutofocus
    [ngbTypeahead]="searchCity"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="googleMapsService.formatter"
    [resultFormatter]="googleMapsService.formatter"
    [ngClass]="inputClass"
    type="text"
    class="form-control text-white"
    placeholder="       City or Address"
    autocomplete="new-search-city"
    (selectItem)="selectItem($event)"
    (input)="onInput($event.target)"
  >
</form>
