import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { plainToClass } from 'class-transformer';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { CityWithChild } from '../../../domains/city-with-child';
import { CountryWithChild } from '../../../domains/country-with-child';
import { CountyWithChild } from '../../../domains/county-with-child';
import { District } from '../../../domains/district';
import { RegionWithChild } from '../../../domains/region-with-child';
import { StateWithChild } from '../../../domains/state-with-child';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorage } from '../../../local-storage/local-storage';
import { DeviceType } from '../../../models/enums/device-type';
import { Labels } from '../../../models/enums/label';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { LocationType } from '../../../models/enums/location-type';
import { SidenavType } from '../../../models/enums/sidenav-type';
import { SearchFormGroup } from '../../../models/form-groups/search-form-group';
import { HashLocationId } from '../../../models/hash-location-id';
import { ExploreRouteParams } from '../../../models/route-params/explore-route-params';
import { SearchParams } from '../../../models/search-params';
import { Select } from '../../../models/select';
import { SearchFiltersPositionWrapper } from '../../../models/wrappers/search-filters-position.wrapper';
import { ExploreService } from '../../../services/explore.service';
import { LayoutSidenavService } from '../../../services/layout-sidenav.service';
import { LoaderService } from '../../../services/loader.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RouterService } from '../../../services/router.service';
import { SearchCityService } from '../../../services/search-city.service';
import { SearchFiltersService } from '../../../services/search-filters.service';
import { SearchParamsService } from '../../../services/search-params.service';
import { HashUtils } from '../../../utils/hash-utils';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnInit, OnChanges {
  @Input() country: CountryWithChild;
  @Input() searchParams: SearchParams;
  @Input() hideTemporary = false;
  @Input() position: SearchFiltersPositionWrapper;
  @Input() isHovered = false;
  @Input() preview = true;
  @Input() isSearching: boolean;

  @Output() stateSelectedChange = new EventEmitter<StateWithChild>();
  @Output() countySelectedChange = new EventEmitter<CountyWithChild>();
  @Output() regionSelectedChange = new EventEmitter<RegionWithChild>();
  @Output() citySelectedChange = new EventEmitter<CityWithChild>();
  @Output() districtSelectedChange = new EventEmitter<District>();
  @Output() rotateIcon = new EventEmitter<boolean>();
  @Output() previewChange = new EventEmitter<boolean>();
  @Output() locationDropdownChange = new EventEmitter<boolean>();
  @Output() localitySelectedChange = new EventEmitter<boolean>();
  @Output() searchClicked = new EventEmitter<boolean>();

  @ViewChild('search') searchRef: ElementRef;
  @ViewChild('countySelectRef') countySelectRef: ElementRef;
  @ViewChild('regionSelectRef') regionSelectRef: ElementRef;
  @ViewChild('citySelectRef') citySelectRef: ElementRef;
  @ViewChild('districtSelectRef') districtSelectRef: ElementRef;
  @ViewChild('titleCitySelectRef') titleCitySelectRef: ElementRef;
  @ViewChild('titleDistrictSelectRef') titleDistrictSelectRef: ElementRef;
  @ViewChild('ngSelectCountyComponent') ngSelectCountyComponent: NgSelectComponent;
  @ViewChild('ngSelectRegionComponent') ngSelectRegionComponent: NgSelectComponent;
  @ViewChild('ngSelectCityComponent') ngSelectCityComponent: NgSelectComponent;
  @ViewChild('ngSelectDistrictComponent') ngSelectDistrictComponent: NgSelectComponent;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  nearByLabel = Labels.NEARBY_LABEL;
  otherLabel = Labels.OTHER_LABEL;
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  searchFormGroup = new SearchFormGroup(this.formBuilder);

  countrySelected: CountryWithChild;
  stateSelected: StateWithChild;
  countySelected: CountyWithChild;
  regionSelected: RegionWithChild;
  citySelected: CityWithChild;
  districtSelected: District;
  stateSelect = new Select();
  countySelect = new Select();
  regionSelect = new Select();
  citySelect = new Select();
  districtSelect = new Select();
  isOwnCity = false;
  hash: string;
  countyHover = false;
  cityHover = false;
  regionHover = false;
  searchHovered: boolean;
  locationHovered: boolean;
  findMeHovered: boolean;
  searchParamsPreviousUrl: string;
  exploreRouteParams: ExploreRouteParams;
  searchOpen = false;

  searchModalCloseDisableLocalStorage = new BooleanLocalStorage(LocalStorageKey.SEARCH_MODAL_CLOSE_DISABLE);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchFiltersService: SearchFiltersService,
    private exploreService: ExploreService,
    private navigatorService: NavigatorService,
    private loaderService: LoaderService,
    private layoutSidenavService: LayoutSidenavService,
    private searchCityService: SearchCityService,
    private searchParamsService: SearchParamsService,
    private routerService: RouterService
  ) {
  }

  ngOnInit(): void {
    this.hash = HashUtils.getCodePositionUniqueHash();

    this.routerService.exploreRouteParamsSubject.subscribe(exploreRouteParams => {
      this.exploreRouteParams = exploreRouteParams;
    });

    this.setForm();

    this.searchFiltersService.stateIdSync.subject.subscribe(hashLocationId => {
      this.checkSearchFormAndHashLocationId(hashLocationId).then(() => {
        this.searchFormGroup.stateId = hashLocationId.id;

        this.stateChange(hashLocationId.id).then();
      });
    });

    this.searchFiltersService.countyIdSync.subject.subscribe(hashLocationId => {
      this.checkSearchFormAndHashLocationId(hashLocationId).then(() => {
        this.searchFormGroup.countyId = hashLocationId.id;

        this.countyChange(hashLocationId.id).then();
      });
    });

    this.searchFiltersService.regionIdSync.subject.subscribe(hashLocationId => {
      this.checkSearchFormAndHashLocationId(hashLocationId).then(() => {
        this.searchFormGroup.regionId = hashLocationId.id;

        this.regionChange(hashLocationId.id).then();
      });
    });

    this.searchFiltersService.cityIdSync.subject.subscribe(hashLocationId => {
      this.checkSearchFormAndHashLocationId(hashLocationId).then(() => {
        this.searchFormGroup.cityId = hashLocationId.id;

        this.cityChange(hashLocationId.id).then();
      });
    });

    this.searchFiltersService.districtIdSync.subject.subscribe(hashLocationId => {
      this.checkSearchFormAndHashLocationId(hashLocationId).then(() => {
        this.searchFormGroup.districtId = hashLocationId.id;

        this.districtChange(hashLocationId.id);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('country') && this.country && changes.country.previousValue === undefined) {
      this.setForm();
    }

    if (
      changes.hasOwnProperty('searchParams') &&
      this.searchParams &&
      (this.position.asTitle || changes.searchParams.previousValue?.path !== changes.searchParams.currentValue.path)
      &&
      this.hash && this.searchFiltersService.initiator !== this.hash
    ) {
      this.setForm();
    }

    if (changes.hasOwnProperty('isHovered')) {
      this.isHovered = changes.isHovered.currentValue;
    }

    if (changes.hasOwnProperty('preview')) {
      this.preview = changes.preview.currentValue;
    }
  }

  get isLocalitySelected(): boolean {
    return this.citySelected != null || this.districtSelected != null;
  }

  private getExplorePath(): string {
    return this.exploreService.getExplorePath(
      this.stateSelected,
      this.countySelected,
      this.regionSelected,
      this.citySelected,
      this.districtSelected,
      this.exploreRouteParams.geoLocation
    );
  }

  private checkSearchFormAndHashLocationId(hashLocationId: HashLocationId): Promise<void> {
    return new Promise(resolve => {
      if (this.searchFormGroup.searchForm && hashLocationId && hashLocationId.hash !== this.hash) {
        resolve();
      }
    });
  }

  private setForm() {
    if (
      this.searchParams &&
      this.country &&
      (!this.searchParamsPreviousUrl || this.searchParamsPreviousUrl !== this.searchParams.path)
    ) {
      if (!this.countrySelected) {
        this.countrySelected = this.country;

        if (this.stateSelect.isEmpty) {
          this.stateSelect.options = [];
          this.countrySelected.states.forEach(state => this.stateSelect.addOption(state.id, state.display));
        }
      }

      this.setSearchParamsToForm(this.searchParams);

      this.searchParamsPreviousUrl = this.searchParams.path;
    }
  }

  private setSearchParamsToForm(searchParams: SearchParams, triggerSync = false) {
    this.searchFormGroup.stateId = searchParams.stateId;

    this.stateChange(searchParams.stateId, true, triggerSync).then(() => {
      this.searchFormGroup.countyId = searchParams.countyId;

      this.countyChange(searchParams.countyId, true, triggerSync).then(() => {
        this.searchFormGroup.regionId = searchParams.regionId;

        this.regionChange(searchParams.regionId, true, triggerSync).then(() => {
          this.searchFormGroup.cityId = searchParams.cityId;

          this.cityChange(searchParams.cityId, true, triggerSync).then(() => {
            this.searchFormGroup.districtId = searchParams.districtId;

            if (searchParams.districtId) {
              this.districtChange(searchParams.districtId, triggerSync);
            } else if (!this.districtSelect.isEmpty) {
              setTimeout(() => {
                this.ngSelectDistrictComponent.open();
              });
            }
          }).catch(() => {
            setTimeout(() => {
              this.ngSelectCityComponent.open();
            });
          });
        });
      }).catch(() => {
        setTimeout(() => {
          this.ngSelectCountyComponent.open();
        });
      });
    });

    this.searchFormGroup.locationName = searchParams.locationName;
  }

  private setCitySelectOptions(cities: CityWithChild[]) {
    cities.forEach(city =>
      this.citySelect.addOption(city.id, city.display, {geoLocation: city.geoLocation})
    );

    this.citySelect.sort();
  }

  private toggleNgSelectComponent(ngSelectComponent: NgSelectComponent, disable: boolean) {
    if (ngSelectComponent !== undefined) {
      ngSelectComponent.setDisabledState(disable);

      const disabledClass = 'ng-select-disabled';

      if (disable) {
        ngSelectComponent.element.classList.add(disabledClass);
      } else {
        ngSelectComponent.element.classList.remove(disabledClass);
      }
    }
  }

  private toggleSelect(select: ElementRef, disable: boolean) {
    if (select !== undefined) {
      select.nativeElement.disabled = disable;
    }
  }

  private toggleSelectLevels(locationType: LocationType, disable: boolean) {
    if (locationType === LocationType.COUNTY) {
      if (this.isMobile) {
        this.toggleSelect(this.countySelectRef, disable);
      } else {
        this.toggleNgSelectComponent(this.ngSelectCountyComponent, disable);
      }
    }

    if ([LocationType.COUNTY, LocationType.REGION].includes(locationType)) {
      if (this.isMobile) {
        this.toggleSelect(this.regionSelectRef, disable);
      } else {
        this.toggleNgSelectComponent(this.ngSelectRegionComponent, disable);
      }
    }

    if ([LocationType.COUNTY, LocationType.REGION, LocationType.CITY].includes(locationType)) {
      if (this.isMobile) {
        this.toggleSelect(this.position.asTitle ? this.titleCitySelectRef : this.citySelectRef, disable);
      } else {
        this.toggleNgSelectComponent(this.ngSelectCityComponent, disable);
      }
    }

    if ([LocationType.COUNTY, LocationType.REGION, LocationType.CITY, LocationType.DISTRICT].includes(locationType)) {
      if (this.isMobile) {
        this.toggleSelect(this.position.asTitle ? this.titleDistrictSelectRef : this.districtSelectRef, disable);
      } else {
        this.toggleNgSelectComponent(this.ngSelectDistrictComponent, disable);
      }
    }
  }

  private fetchStateCounties(stateId: number, fetchCounties = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.stateSelected.counties.length > 0) {
        resolve();
      } else if (fetchCounties) {
        this.loaderService.showLoader();

        this.toggleSelectLevels(LocationType.COUNTY, true);

        this.exploreService.getCountiesByStateId(stateId)
          .subscribe({
            next: counties => {
              this.toggleSelectLevels(LocationType.COUNTY, false);

              this.loaderService.hideLoader();

              if (this.stateSelected.counties.length === 0) {
                this.stateSelected.counties = counties;
              }

              this.stateSelected.countiesLoaded = true;

              resolve();
            }, error: error => reject(error)
          });
      }
    });
  }

  private fetchCountyRegions(countyId: number, fetchRegions = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.countySelected.regions.length > 0) {
        resolve();
      } else if (fetchRegions) {
        this.loaderService.showLoader();

        this.toggleSelectLevels(LocationType.REGION, true);

        this.exploreService.getCountyRegions(countyId)
          .subscribe({
            next: regions => {
              this.toggleSelectLevels(LocationType.REGION, false);

              this.loaderService.hideLoader();

              if (this.countySelected.regions.length === 0) {
                this.countySelected.regions = regions;
              }

              this.countySelected.regionsLoaded = true;

              resolve();
            }, error: error => reject(error)
          });
      }
    });
  }

  private fetchCountyCities(countyId: number, fetchCities = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.countySelected.cities.length > 0) {
        resolve();
      } else if (fetchCities) {
        this.loaderService.showLoader();

        this.toggleSelectLevels(LocationType.CITY, true);

        this.exploreService.getCitiesByCountyId(countyId)
          .subscribe({
            next: cities => {
              this.toggleSelectLevels(LocationType.CITY, false);

              this.loaderService.hideLoader();

              if (this.countySelected.cities.length === 0) {
                this.countySelected.cities = cities;
              }

              this.countySelected.citiesLoaded = true;

              resolve();
            }, error: error => reject(error)
          });
      }
    });
  }

  private fetchRegionCities(regionId: number, fetchCities = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.regionSelected.cities.length > 0) {
        resolve();
      } else if (fetchCities) {
        setTimeout(() => {
          this.loaderService.showLoader();

          this.toggleSelectLevels(LocationType.CITY, true);
        });

        this.exploreService.getCitiesByRegionId(regionId)
          .subscribe({
            next: cities => {
              this.toggleSelectLevels(LocationType.CITY, false);

              this.loaderService.hideLoader();

              if (this.regionSelected.cities.length === 0) {
                this.regionSelected.cities = cities;
              }

              this.regionSelected.citiesLoaded = true;

              resolve();
            }, error: error => reject(error)
          });
      }
    });
  }

  private fetchCityDistricts(cityId: number, fetchCityDistricts = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.citySelected.districts.length > 0) {
        resolve();
      } else if (fetchCityDistricts) {
        this.loaderService.showLoader();

        this.toggleSelectLevels(LocationType.DISTRICT, true);

        this.exploreService.getDistrictsByCityId(cityId)
          .subscribe({
            next: districts => {
              this.toggleSelectLevels(LocationType.DISTRICT, false);

              this.loaderService.hideLoader();

              this.citySelected.districts = districts;

              this.citySelected.districtsLoaded = true;

              resolve();
            }, error: error => reject(error)
          });
      }
    });
  }

  private emitSearch(locationType: LocationType) {
    if (this.searchParamsLocalStorage) {
      this.searchFiltersService.lock(this.hash);

      const searchParamsForm = plainToClass(SearchParams, this.searchFormGroup.rawValue);
      const searchParamsSaved = this.searchParamsLocalStorage.getItem();

      searchParamsSaved.stateId = searchParamsForm.stateId;
      searchParamsSaved.locationName = searchParamsForm.locationName;
      searchParamsSaved.countyId = searchParamsForm.countyId;
      searchParamsSaved.regionId = searchParamsForm.regionId;
      searchParamsSaved.cityId = searchParamsForm.cityId;
      searchParamsSaved.districtId = searchParamsForm.districtId;
      searchParamsSaved.locationId = searchParamsForm.districtId || searchParamsForm.cityId || null;
      searchParamsSaved.locationType = locationType;

      searchParamsSaved.path = this.getExplorePath();

      this.searchParamsLocalStorage.setItem(searchParamsSaved);

      this.navigatorService.goToUrl(searchParamsSaved.path).then();
    }
  }

  private changePathAndEmitSearch(triggerSync = false, locationType: LocationType) {
    if (!this.searchFiltersService.lockOthers) {
      const explorePath = this.getExplorePath();

      if (window.location.pathname !== explorePath) {
        this.navigatorService.changeLocationTo(explorePath);

        if (triggerSync) {
          this.emitSearch(locationType);
        }
      }
    }
  }

  stateChange(stateId: number, fetchCounties = false, triggerSync = false) {
    if (triggerSync) {
      this.searchFiltersService.stateIdSync.setId(this.hash, stateId);
    }

    return new Promise<void>((resolve, reject) => {
      this.searchFormGroup.countyId = null;
      this.searchFormGroup.regionId = null;
      this.searchFormGroup.cityId = null;
      this.searchFormGroup.districtId = null;
      this.countySelect.options = [];
      this.regionSelect.options = [];
      this.citySelect.options = [];
      this.districtSelect.options = [];
      this.countySelected = null;
      this.regionSelected = null;
      this.citySelected = null;
      this.districtSelected = null;

      this.countySelectedChange.emit(null);
      this.regionSelectedChange.emit(null);

      if (this.countrySelected && stateId) {
        this.stateSelected = this.countrySelected.states.find(state => state.id === +stateId);

        this.stateSelectedChange.emit(this.stateSelected);

        if (this.stateSelected) {
          this.fetchStateCounties(stateId, fetchCounties).then(() => {
            if (this.stateSelected.counties.length > 0) {
              this.stateSelected.counties.forEach(county =>
                this.countySelect.addOption(county.id, county.display, {geoLocation: county.geoLocation})
              );
            }

            resolve();
          });
        } else {
          resolve();
        }

        this.setIsLocalitySelected();
      } else {
        reject();
      }
    });
  }

  onStateChange(stateItem: any) {
    const stateId = +stateItem.value;

    this.stateChange(stateId, true, true).then();

    this.locationDropdownChanged();
  }

  countyChange(countyId: number, fetchRegionsAndCities = false, triggerSync = false) {
    if (triggerSync) {
      this.searchFiltersService.countyIdSync.setId(this.hash, countyId);
    }

    return new Promise<void>((resolve, reject) => {
      this.searchFormGroup.regionId = null;
      this.searchFormGroup.cityId = null;
      this.searchFormGroup.districtId = null;
      this.regionSelect.options = [];
      this.citySelect.options = [];
      this.regionSelected = null;
      this.citySelected = null;
      this.districtSelected = null;
      this.regionSelectedChange.emit(null);

      if (this.stateSelected && countyId) {
        this.countySelected = this.stateSelected.counties.find(countyI => countyI.id === +countyId);

        this.countySelectedChange.emit(this.countySelected);

        if (this.countySelected === undefined) {
          this.searchFormGroup.countyId = null;

          reject();
        } else {
          this.countySelect.sortOptionsByNearest(this.countySelected.geoLocation);

          this.fetchCountyRegions(countyId, fetchRegionsAndCities).then(() => {
            if (this.countySelected.regions.length > 0) {
              this.countySelected.regions.forEach(region => this.regionSelect.addOption(region.id, region.display));
            }

            if (!this.regionSelect.isEmpty) {
              resolve();
            } else if (triggerSync) {
              this.changePathAndEmitSearch(triggerSync, LocationType.COUNTY);
            } else {
              this.fetchCountyCities(countyId, fetchRegionsAndCities).then(() => {
                if (this.countySelected.cities.length > 0) {
                  this.setCitySelectOptions(this.countySelected.cities);
                }

                if (this.countySelected.hasSingleCity()) {
                  const cityId = this.countySelected.singleCity().id;

                  this.searchFormGroup.cityId = cityId;

                  this.cityChange(cityId).then(() => {
                    resolve();
                  });
                } else {
                  resolve();
                }
              });
            }
          });
        }

        this.setIsLocalitySelected();
      } else {
        reject();
      }
    });
  }

  onCountyChange(countyItem: any) {
    this.countyHover = false;

    const countyId = +countyItem.value;

    this.countyChange(countyId, true, true).then();

    this.locationDropdownChanged();
  }

  regionChange(regionId: number, fetchCities = false, triggerSync = false) {
    if (triggerSync) {
      this.searchFiltersService.regionIdSync.setId(this.hash, regionId);
    }

    return new Promise<void>((resolve, reject) => {
      if (this.countySelected && regionId) {
        this.searchFormGroup.cityId = null;
        this.searchFormGroup.districtId = null;
        this.citySelect.options = [];
        this.districtSelect.options = [];
        this.citySelected = null;
        this.districtSelected = null;

        this.regionSelected = this.countySelected.regions.find(regionI => regionI.id === +regionId);

        this.regionSelectedChange.emit(this.regionSelected);

        if (this.regionSelected === undefined) {
          this.searchFormGroup.regionId = null;

          reject();
        } else {
          this.fetchRegionCities(regionId, fetchCities).then(() => {
            if (this.regionSelected.cities.length > 0) {
              this.setCitySelectOptions(this.regionSelected.cities);
            }

            resolve();
          });
        }

        this.setIsLocalitySelected();
      } else {
        resolve();
      }
    });
  }

  onRegionChange(regionItem: any) {
    const regionId = +regionItem.value;

    this.regionChange(regionId, true, true).then();

    this.locationDropdownChanged();
  }

  cityChange(cityId: number, fetchDistricts = false, triggerSync = false) {
    if (triggerSync) {
      this.searchFiltersService.cityIdSync.setId(this.hash, cityId);
    }

    return new Promise<void>((resolve, reject) => {
      this.searchFormGroup.districtId = null;
      this.districtSelect.options = [];
      this.districtSelected = null;

      if (this.countySelected && cityId) {
        this.isOwnCity = this.searchParamsLocalStorage.getItem()?.cityId === cityId;

        if (this.regionSelected) {
          this.citySelected = this.regionSelected.cities.find(city => city.id === +cityId);
        } else {
          this.citySelected = this.countySelected?.cities?.find(city => city.id === +cityId);
        }

        this.citySelectedChange.emit(this.citySelected);

        if (this.citySelected === undefined) {
          this.searchFormGroup.cityId = null;

          reject();
        } else if (!this.citySelected.bigCity) {
          this.searchFormGroup.locationName = this.citySelected.display;

          this.changePathAndEmitSearch(triggerSync, LocationType.CITY);
        } else {
          this.fetchCityDistricts(cityId, fetchDistricts).then(() => {
            if (this.citySelected && this.citySelected.districts.length > 0) {
              this.citySelected.districts.forEach(district =>
                this.districtSelect.addOption(district.id, district.display)
              );
            }

            this.districtSelect.sort();

            resolve();
          });
        }

        this.setIsLocalitySelected();
      } else {
        reject();
      }
    });
  }

  onCityChange(cityItem: any) {
    this.cityHover = false;

    const cityId = +cityItem.value;

    this.searchFiltersService.cityIdSync.setId(this.hash, cityId);

    this.cityChange(cityId, true, true).then(() => {
      if (this.citySelected.bigCity && this.districtSelect.hasSingleOption()) {
        const districtId = +this.districtSelect.options[0].value;
        this.searchFormGroup.districtId = districtId;
        this.districtChange(districtId);
      }
    });

    this.locationDropdownChanged();
  }

  districtChange(districtId: number, triggerSync = false) {
    if (triggerSync) {
      this.searchFiltersService.districtIdSync.setId(this.hash, districtId);
    }

    if (this.citySelected && districtId) {
      this.isOwnCity = this.searchParamsLocalStorage.getItem()?.districtId === districtId;

      this.districtSelected = this.citySelected?.districts?.find(district => district.id === +districtId);

      this.districtSelectedChange.emit(this.citySelected);

      if (this.districtSelected === undefined) {
        this.searchFormGroup.districtId = null;
      } else {
        this.searchFormGroup.locationName = this.districtSelected.display;

        this.changePathAndEmitSearch(triggerSync, LocationType.DISTRICT);
      }

      this.setIsLocalitySelected();
    }
  }

  onDistrictChange(districtItem: any) {
    this.cityHover = false;

    const districtId = +districtItem.value;

    this.searchFiltersService.districtIdSync.setId(this.hash, districtId);

    this.districtChange(districtId, true);

    this.locationDropdownChanged();
  }

  locationDropdownChanged(search = false) {
    if (!this.exploreRouteParams.geoLocation) {
      this.locationDropdownChange.emit(true);
    } else {
      this.searchParams = this.searchParamsService.removeGeoLocationFromPath(this.searchParams);
      this.exploreRouteParams.geoLocation = null;

      if (!search) {
        this.navigatorService.changeLocationTo(this.searchParams.path);
      } else {
        this.searchModalCloseDisableLocalStorage.setItem(true);

        setTimeout(() => {
          this.searchModalCloseDisableLocalStorage.setItem(false);
        }, 1000);
      }
    }
  }

  findMe() {
    this.searchParamsService.getByMyGeoLocation(true).then(searchParamByGeoLocation => {
      this.goToSearchParamsPath(searchParamByGeoLocation);
    });
  }

  goToSearchParamsPath(searchParams: SearchParams) {
    this.searchParamsLocalStorage.setItem(searchParams);
    this.searchParams = searchParams;

    this.navigatorService.goToUrl(this.searchParams.path).then();
  }

  openMapWithCounty() {
    this.navigatorService.goToMaps(
      `${this.countySelected.fullDisplay}, ${this.stateSelected.key}`
    );
  }

  openMapWithRegion() {
    this.navigatorService.goToMaps(
      `${this.regionSelected.display}, ${this.countySelected.fullDisplay}, ${this.stateSelected.key}`
    );
  }

  openMapWithCity() {
    this.navigatorService.goToMaps(
      `${this.searchParams.locationName}, ${this.countySelected.fullDisplay}, ${this.stateSelected.key}`
    );
  }

  setIsLocalitySelected() {
    this.localitySelectedChange.emit(this.isLocalitySelected);
  }

  openSearchCityModal() {
    this.searchCityService.openSearchCityModalSubject.next(true);
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(true);
  }

  onSearchCityModalClosed() {
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(false);
  }
}
