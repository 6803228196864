export const environment = {
  production: true,
  apiEndpoint: 'https://api.adik.rykersoft.com',
  defaultImage: 'https://adik-icons.imgix.net/default-food-v5.png',
  defaultMenuImage: 'https://adik-icons.imgix.net/default-menu-image-v5.png',
  defaultRestaurantImage: 'https://adik-icons.imgix.net/default-background-v2.jpg',
  defaultAvatar: 'https://adik-icons.imgix.net/default-avatar.png',
  emailLogo: 'https://adik-icons.imgix.net/email-logo.png',
  mmmmLogo: 'https://adik-icons.imgix.net/mmmm-logo.svg',
  favoritesBackgroundImage: 'https://adik-icons.imgix.net/favorites-background-v2.jpeg',
  exploreBackgroundImage: 'https://adik-icons.imgix.net/explore-background-v3.jpeg',
  exploreBackgroundDesktopImage: 'https://adik-icons.imgix.net/explore-background-desktop-v3.jpg',
  loginBackgroundImage: 'https://adik-icons.imgix.net/login-background.jpg',
  gemBackgroundImage: 'https://adik-icons.imgix.net/gem-background.jpeg',
  instagramAppId: '859566161185129',
  facebookAppId: '235522254305982',
  domain: 'adik.rykersoft.com',
  defaultRestaurantHost: 'yoursite',
  googleClientId: '632551795551-135spj9hrje3u4pg1u03g422gvsogsr6.apps.googleusercontent.com',
  paidFeaturesEnabled: false,
  getstreamApiKey: 'tfbqvnaznjvv',
  getstreamAppId: '1125539',
  imgixDomain: 'adik-imgs.imgix.net',
  ipLookupKey: '3K5ZRaNbltsucYsxF15u',
  adminBackgroundImage: {
    url: 'https://images.unsplash.com/photo-1543164939-264c96ac5b6a?ixlib=rb-1.2.1&auto=format&fit=crop',
    blurHash: 'eG8gjV9ZIoxajF%hRPaxj]f60f-U%1NHW;9Y%2kCaebH#%XANIs,oL'
  },
  timeBlockBackgroundImage: 'https://adik-icons.imgix.net/time-block-background-3.png',
  membershipDesktopBackground: 'https://adik-icons.imgix.net/mbership-background.jpg',
  mmmGirlSound: 'assets/sounds/mmmm-girl-2.mp3'
};
