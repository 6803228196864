<div
  *ngIf="isMobile"
  [ngClass]="{
    'distance-filter-mobile-navbar position-fixed': positionWrapper.isNavbar,
    'top-31px': searchParams.geoLocation,
    'distance-filter-mobile-footer bg-white border-bottom-radius-3': positionWrapper.isModal
  }"
  class="d-flex w-100"
>
  <div class="flex-content-vc pr-2">
    <a appHrefNone class="flex-content-vc-hc" (click)="goToCityRestaurants()">
      <i class="icon-v2 scale-08 close-circle"></i>
    </a>
  </div>

  <div class="flex-fill my-auto px-2 py-1">
    <nouislider
      [(ngModel)]="searchResult.distanceFilterMobile"
      [min]="searchResult?.distanceMin || 1"
      [max]="searchResult?.distanceMax || 100"
      [behaviour]="'tap-drag'"
      [config]="rangeConfig"
      (change)="distanceChange()"
    ></nouislider>
  </div>

  <div class="flex-content-vc pl-2">
    &nbsp;
    <app-restaurants-picker
      [position]="restaurantsPickerPosition.DISTANCE_SLIDER"
      [searchResult]="searchResult"
      (restaurantClicked)="onRestaurantSelected($event)"
    ></app-restaurants-picker>
  </div>
</div>

<div *ngIf="!isMobile" class="distance-filter-desktop mt-3">
  <div
    class="search-filters-desktop-location-text text-uppercase mb-3"
    (mouseover)="milesHovered = true"
    (mouseleave)="milesHovered = false"
  >
    <span
      [ngClass]="{'opacity-50': !milesHovered, 'opacity-100': milesHovered}"
      class="opacity-50 cursor-pointer"
    >
      Miles
    </span>

    <span
      *ngIf="milesHovered || distanceHover"
      [ngClass]="{'opacity-50': !distanceHover, 'opacity-100': distanceHover}"
      (mouseover)="distanceHover = true"
      (mouseleave)="distanceHover = false"
    >
      <a
        appHrefNone
        class="text-white text-uppercase"
        (click)="goToCityRestaurants()"
      >
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Clear
      </a>
    </span>
  </div>

  <div class="form-group">
    <label
      *ngFor="let desktopDistance of desktopDistances"
      class="custom-control custom-radio custom-radio-inverted mb-0-6rem"
    >
      <input
        [(ngModel)]="searchResult.distanceFilterDesktop"
        [value]="desktopDistance.value"
        type="radio"
        class="custom-control-input outline-none"
        name="distance"
        (ngModelChange)="distanceChange()"
      />

      <span class="custom-control-label cursor-pointer pl-2">{{ desktopDistance.label }}</span>
    </label>
  </div>
</div>
