<div
  class="user-sidenav h-100"
  (mouseover)="sidebarHover = true"
  (mouseleave)="sidebarHover = false"
>
  <div class="w-100 h-100 flex-content-hr">
    <div class="user-sidenav-menu text-white flex-content-hc flex-column opacity-07-to-1-on-hover">
      <div
        *ngIf="!isSearchModalOpen"
        class="flex-content-hc mb-2rem"
        (mouseover)="setSidenavHover(true)"
        (mouseleave)="setSidenavHover(false)"
      >
        <a
          appHrefNone
          [ngClass]="{'icon-v2 chains-circle-white': !isUserPage || sidebarHover,'icon chains-circle': !sidebarHover && isUserPage}"
          class="cursor-pointer"
          (click)="openMbershipModal()"
        ></a>
      </div>

      <div *ngIf="isLoggedIn && !isSearchModalOpen && !isUserPage" class="flex-content-hc mb-2rem">
        <a class="icon-v2 profile-white cursor-pointer" (click)="goToUser()"></a>
      </div>

      <div *ngIf="!isSearchModalOpen && isUserPage" class="flex-content-hc mb-2rem">
        <a
          [ngClass]="{'profile-full': sidenavHover || sidebarHover,'profile-dark': !sidebarHover}"
          class="icon-v2 cursor-auto"
        ></a>
      </div>

      <div *ngIf="isExplorePage && !isSearchModalOpen" class="flex-content-hc mb-2rem">
        <a class="icon-v2 explore-full cursor-auto"></a>
      </div>

      <div *ngIf="!isExplorePage" class="flex-content-hc mb-2rem">
        <a
          routerLink="/"
          [ngClass]="{'explore': !sidenavHover && !sidebarHover,'explore-white': !isUserPage || sidebarHover}"
          class="icon-v2 cursor-pointer"
        ></a>
      </div>

      <div *ngIf="isExplorePage && !isSearchModalOpen && isLoggedIn" class="flex-content-hc mb-2rem">
        <a
          appHrefNone
          class="icon heart-white-empty cursor-pointer"
          (click)="onFavoriteClick()"
        ></a>
      </div>

      <div *ngIf="isExplorePage && !isSearchModalOpen && !isRestaurantPage" class="flex-content-hc">
        <a
          appHrefNone
          class="icon-v2 video-white-button cursor-pointer"
          (click)="openVideoModal()"
        ></a>
      </div>

      <div *ngIf="isRestaurantPage" class="flex-content-hc mb-2rem">
        <a
          appHrefNone
          class="cursor-pointer icon-v2 clock"
          (click)="openRestaurantModal()"
        ></a>
      </div>

      <div class="mt-auto"></div>
    </div>
  </div>
</div>

<ng-template #restaurantModalRef>
  <app-restaurant-modal [restaurant]="currentRestaurant" class="h-100"></app-restaurant-modal>
</ng-template>

<ng-template #videoModalRef>
  <app-video-desktop-modal></app-video-desktop-modal>
</ng-template>
