import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IsModalOpen } from '../../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../../decorators/is-sidenav-mouse-over.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { ModalType } from '../../../../models/enums/modal-type';
import { SidenavType } from '../../../../models/enums/sidenav-type';

@Component({
  selector: 'app-layout-front',
  templateUrl: './layout-front.component.html',
  styleUrls: ['./layout-front.component.scss']
})
export class LayoutFrontComponent implements OnInit {
  @IsModalOpen(ModalType.LOGIN) isLoginModalOpen: boolean;
  @IsModalOpen(ModalType.RESTAURANT_SQUARE) isRestaurantModalOpen: boolean;
  @IsModalOpen(ModalType.SUBSCRIBE_DESKTOP) isSubscribeModalOpen: boolean;
  @IsModalOpen(ModalType.SEARCH_SQUARE) isSearchModalOpen: boolean;
  @IsModalOpen(ModalType.FAVORITE_SQUARE) isFavoriteModalOpen: boolean;
  @IsModalOpen(ModalType.POST_SQUARE) isPostModalOpen: boolean;
  @IsModalOpen(ModalType.VIDEO_DESKTOP) isVideoModalOpen: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;
  @IsSidenavMouseOver([SidenavType.RIGHT]) isSidenavRightMouseOver: boolean;

  outsideMMMMLogo = false;
  isPrivacy = false;
  isTerms = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setTermsOrPrivacy();
      }
    });

    this.setTermsOrPrivacy();

    const html = document.querySelector('html');

    html.classList.add('layout-collapsed');
    html.classList.remove('layout-fixed');
  }

  @ModalClose()
  closeModal() {
    this.outsideMMMMLogo = false;
  }

  private setTermsOrPrivacy() {
    this.isPrivacy = this.router.url.startsWith('/privacy');

    this.isTerms = this.router.url.startsWith('/terms');
  }
}
