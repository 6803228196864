<div *appIfIsMobile class="top-input-modal safe-area-top">
  <div>
    <i *ngIf="!hiddenPlaceholder" class="icon-v2 search-white"></i>

    <app-search-city-input
      inputClass="top-input-modal-input search-filters-mobile-location-input"
      (placeholderHidden)="hiddenPlaceholder = $event"
      (inputValue)="searchInputValue($event)"
      (selectPlace)="onSelectPlace($event)"
    ></app-search-city-input>
  </div>

  <div
    *ngIf="!searchValueExists"
    class="search-city-find-me flex-content-vc px-1-25rem safe-area-top"
  >
    <a appHrefNone class="text-white" (click)="onFindMe()">Find me</a>
  </div>
</div>

<div *appIfIsDesktop class="top-input-modal font-size-1-05rem modal-padding margin-top-27px">
  <i *ngIf="!hiddenPlaceholder" class="icon-v2 search-white margin-left-40px"></i>

  <i class="icon-v2 find-me opacity-05-to-1-on-hover position-absolute-find-me cursor-pointer" (click)="onFindMe()"></i>

  <app-search-city-input
    inputClass="top-input-modal-input search-filters-desktop-location-input text-white font-size-1-05rem px-2-5rem"
    (placeholderHidden)="hiddenPlaceholder = $event"
    (inputValue)="searchInputValue($event)"
    (selectPlace)="onSelectPlace($event)"
  ></app-search-city-input>
</div>
