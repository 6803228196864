import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Place } from '../../../../../models/place';
import { ExploreService } from '../../../../../services/explore.service';
import { GoogleMapsService } from '../../../../../services/google-maps.service';

@Component({
  selector: 'app-search-city-input',
  templateUrl: './search-city-input.component.html',
  styleUrls: []
})
export class SearchCityInputComponent implements OnInit {
  @Input() inputClass: string;

  @Output() placeholderHidden = new EventEmitter<boolean>();
  @Output() inputValue = new EventEmitter<string>();
  @Output() selectPlace = new EventEmitter<Place>();

  constructor(public googleMapsService: GoogleMapsService, private exploreService: ExploreService) {
  }

  ngOnInit(): void {
    this.placeholderHidden.emit(false);
  }

  searchCity: any = (text: Observable<string>) => {
    return text.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.googleMapsService.getPredictions(term, 'name').pipe(
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap()
    );
  }

  onInput(target: any) {
    const value = target.value;
    this.placeholderHidden.emit(value !== '');
    this.inputValue.emit(value);
  }

  selectItem(placeEvent: NgbTypeaheadSelectItemEvent<Place>) {
    this.selectPlace.emit(placeEvent.item);
  }
}
