<div class="restaurant-modal-body d-flex p-0 modal-body h-100 w-100">
  <div class="h-100 w-100 z-index-0">
    <ng-container *ngIf="restaurant">
      <div
          *ngIf="restaurant.googleBackgroundImages.length == 0"
          [appBackgroundUrl]="backgroundImage || ''"
          class="position-absolute-0"
      ></div>

      <div *ngIf="restaurant.googleBackgroundImages.length > 0 && backgroundImagesAreReady" class="position-absolute-0">
        <swiper #restaurantBackgroundSwiper [config]="backgroundImagesConfig" class="position-absolute-0">
          <ng-template *ngFor="let image of restaurant.googleBackgroundImages" swiperSlide>
            <div
              [ngStyle]="{backgroundImage: 'url(' + (image | googleImage: 1220) + ')'}"
              class="position-absolute-0 background-center-cover-no-repeat"
            ></div>

            <div class="restaurant-overlay-pagination w-100">
              <div
                class=" d-flex justify-content-center restaurant-preview-swiper-pagination restaurant-background-swiper-navigation"
              ></div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </ng-container>

    <div *ngIf="isBasketReady" class="d-flex flex-column h-100">
      <div class="d-flex h-100">
        <div
          [ngClass]="{'cursor-pointer': owner}"
          class="restaurant-modal-left left-content-container h-100 d-flex flex-column"
          (click)="owner = false"
        >
          <div
            class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative"
            [ngClass]="{
              'backdrop-filter-brightness-1': sidenavHover,
              'backdrop-filter-brightness-0-15' : !sidenavHover
            }"
          >
            <div
              [ngClass]="{
                'restaurant-desktop-header-no-logo': !restaurant?.details.logo,
                'restaurant-desktop-header-logo': restaurant?.details.logo
              }"
              class="text-white text-center"
            >
              <div
                *ngIf="restaurant"
                class="cursor-pointer"
                (mouseover)="sidenavHover = true"
                (mouseleave)="sidenavHover = false"
                (click)="nextSlide()"
              >
                <ng-container *ngIf="restaurant.details.logo">
                  <img
                    appBlurImg
                    [path]="restaurant.details.logo.path"
                    [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
                    [title]="restaurant.nameView"
                    [alt]="restaurant.nameView"
                    class="logo-img"
                  />
                </ng-container>

                <ng-container *ngIf="!restaurant.details.logo">
                  <div class="restaurant-desktop-header-name mx-5 text-shadow-70">
                    {{
                      restaurant.nameView |
                        abbreviate:false:'' |
                        city: restaurant.cityNameToRemove
                    }}
                  </div>

                  <span *ngIf="restaurant.secondName" class="font-size-1-1rem text-shadow-70">
                    {{ restaurant.secondNameView }}
                  </span>
                </ng-container>
              </div>
            </div>

            <!-- TODO SEARCH-> add loader before restaurant loading -->
            <div
              [ngClass]="{'d-none': sidenavHover}"
              class="restaurant-hours-desktop-content"
            >
              <div *ngIf="restaurant?.periodsLength" class="position-relative text-white rest-hours-padding">
                <app-restaurant-hours
                  [restaurantHoursSections]="restaurant.hoursSectionsView"
                  [isDesktop]="true"
                  [isModal]="true"
                ></app-restaurant-hours>
              </div>
            </div>

            <div class="m-auto"></div>

            <div *ngIf="!restaurant?.isOwned" class="pb-1 d-flex flex-column align-items-center">
              <i
                [ngClass]="{'d-none': sidenavHover}"
                class="icon-v2 stats opacity-30 cursor-pointer"
                (click)="statsClicked()"
              ></i>
            </div>

            <div
              *ngIf="!restaurant?.isOwned"
              class="font-size-0-98rem z-index-1 p-0 pb-3 text-white d-flex cursor-pointer"
              (click)="statsClicked()"
            >
                <span
                  [ngClass]="{'d-none': sidenavHover}"
                  class="text-center w-100"
                >{{ restaurant?.statRating | shortNumberFormat }}</span>
            </div>

            <!-- TODO SEARCH-> add loader before restaurant loading -->
            <div class="flex-content-vc text-white text-center pb-3 preview-phone cursor-pointer">
              <app-info-bar
                *ngIf="restaurant"
                [isModal]="true"
                [isRestaurantModal]="true"
                [typeCategorySection]=typeCategorySection
                [restaurant]="restaurant"
                [ngClass]="{'d-none': sidenavHover}"
                class="w-100 font-size-0-98rem"
              ></app-info-bar>
            </div>
          </div>
        </div>

        <div
          *ngIf="!basket.isEmpty() && !owner && basketClick"
          class="content-container font-size-0-98rem"
        >
          <app-basket-modal [ngClass]="{'d-none': sidenavHover}" [basket]="basket"></app-basket-modal>
        </div>

        <div
          *ngIf="(basket?.isEmpty() && !owner) || !basketClick"
          class="restaurant-modal-right content-container d-flex flex-column position-relative h-100"
        >
          <div
            #modalContent
            [ngClass]="{'d-none': sidenavHover}"
            class="restaurant-modal-right-content bg-dark-on-modal position-absolute-0"
          >
            <div
              *ngIf="restaurantEmbeds.length === 0 && restaurant?.slideGalleryFoods.empty && !needToShowInstagram && !needToShowVideo"
              class="flex-content-vc-hc bg-dark-on-modal w-100 h-100"
            >
              <div
                class="street-line flex-content-vc-hc text-white opacity-05-to-1-on-hover font-size-0-98rem font-weight-400"
                (click)="onMMMMIconClick()"
              >
                Link video
              </div>
            </div>

            <!-- TODO SEARCH-> add loader before restaurant loading -->
            <app-restaurant-slide-gallery
              *ngIf="restaurant && needToShowMenu"
              [restaurant]="restaurant"
              [backgroundImage]="backgroundImage"
            ></app-restaurant-slide-gallery>

            <ng-container *ngIf="restaurantEmbeds.length == 0 && !needToShowVideo && !needToShowInstagram">
              <div
                *ngIf="
                  restaurant &&
                  needToShowMenu &&
                  !restaurant.instagram &&
                  igUsernameSuggestion?.id &&
                  igUsernameSuggestion.status == reviewStatus.PENDING
                "
                class="restaurant-modal-instagram flex-content-vc px-4 py-3"
              >
                <div class="restaurant-modal-instagram-icon flex-content-vc">
                  <span class="icon-v2 instagram-white"></span>

                  <span class="font-weight-semibold text-white ml-2">
                    {{ igUsernameSuggestion?.suggestion }}
                  </span>
                </div>

                <div class="restaurant-modal-instagram-text font-size-0-85rem text-muted text-center">
                  <span>Pending approval</span>
                </div>

                <div class="restaurant-modal-instagram-btn flex-content-hr">
                  <button
                    type="button"
                    class="btn btn-dark text-transform-none font-size-0-85rem"
                    (click)="onCancelInstagram(igUsernameSuggestion)"
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <ng-container *ngIf="!restaurant?.slideGalleryFoods.empty">
                <ng-template [ngTemplateOutlet]="videoIcon"></ng-template>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="needToShowVideo">
              <div class="bottom-section d-flex flex-column mx-2rem pt-3">
                <div class="input-group">
                  <input
                    #videoInput
                    type="url"
                    class="form-control border-white box-shadow-none text-white"
                    placeholder="Link a video featuring {{ restaurant?.nameView | abbreviate: false: '' }}"
                    (input)="validateUrl($event)"
                  />

                  <span *ngIf="inputUrlValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none"
                      (click)="onVideoSubmitClick(videoInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                </div>

                <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    YouTube • TikTok • Rumble • etc
                  </span>

                  <button
                    type="button"
                    class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                    (click)="restoreMenu()"
                  >
                    Cancel
                  </button>
                </div>
              </div>

              <div
                *ngIf="needToShowInstagram"
                class="bottom-section d-flex flex-column mx-2rem"
                [ngClass]="{'pt-3': !mmmmVideoMenu, 'pt-5-5rem': mmmmVideoMenu}"
              >
                <div class="input-group">
                  <input
                    #instagramInput
                    type="text"
                    required
                    minlength="3"
                    maxlength="30"
                    placeholder="Add Instagram username"
                    class="form-control border-white box-shadow-none text-white"
                    (input)="validateTextInput($event)"
                  />

                  <span *ngIf="inputTextValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none ig-submit-btn"
                      (click)="onInstagramSubmitClick(instagramInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                </div>

                <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    For {{ restaurant.secondNameView }}
                  </span>

                  <button
                    *ngIf="!mmmmVideoMenu"
                    type="button"
                    class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                    (click)="restoreMenu()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </ng-container>

            <div
              *ngIf="!needToShowInstagram && !needToShowVideo && restaurantEmbeds.length > 0"
              id="myVideo"
              class="text-center bg-black"
            >
              <ngx-simplebar #simpleBar class="w-100 bg-dark">
                <div *ngFor="let restaurantEmbed of restaurantEmbeds; let last = last;" [ngClass]="{'mb-3': !last}">
                  <app-embed-video
                    *ngIf="restaurant"
                    [restaurant]="restaurant"
                    [embed]="restaurantEmbed"
                    (deleteEmbed)="onDeleteUserEmbed(restaurantEmbed)"
                  >
                  </app-embed-video>
                </div>
              </ngx-simplebar>

              <ng-template [ngTemplateOutlet]="videoIcon"></ng-template>
            </div>
          </div>
        </div>

        <div
          *ngIf="owner"
          id="owner-container"
          class="content-container flex-column d-flex h-100 position-relative"
        >
          <div
            [ngClass]="{'d-none': sidenavHover}"
            class="content-container h-100 d-flex flex-column bg-dark"
          >
            <div
              class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative backdrop-filter-brightness-0-15"
            >
              <div class="position-absolute-0 font-size-1rem d-flex flex-column">
                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="w-100 d-flex justify-content-center"
                  (click)="owner = false"
                >
                  <div class="icon m-large-white scale-7 mt-7-4rem"></div>
                </div>

                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="text-white w-100 d-flex justify-content-center mt-7rem"
                  (click)="owner = false"
                >
                  The Mber community
                </div>

                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="text-white w-100 d-flex justify-content-center mt-3"
                  (click)="owner = false"
                >
                  M powers owners to share
                </div>

                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="text-white w-100 d-flex justify-content-center mt-3"
                  (click)="owner = false"
                >
                  * Complete menus with updates *
                </div>

                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="text-white w-100 d-flex justify-content-center mt-3"
                  (click)="owner = false"
                >
                  * Holiday hours & events *
                </div>

                <div
                  [ngClass]="{'cursor-pointer': owner}"
                  class="text-white w-100 d-flex justify-content-center mt-3"
                  (click)="owner = false"
                >
                  * Allergen warnings *
                </div>

                <div [ngClass]="{'cursor-pointer': owner}" class="m-auto" (click)="owner = false"></div>

                <input
                  autofocus
                  [ngClass]="{'cursor-pointer': owner}"
                  class="visibility-hidden" (click)="owner = false"
                >

                <div class="w-100 d-flex justify-content-center pb-085-rem">
                  <div
                    #dropdownRef
                    ngbDropdown
                    appNgbDropdownOnHover
                    placement="top-right"
                    class="mr-1-5rem cursor-pointer"
                  >
                    <div
                      ngbDropdownToggle
                      class="text-white text-transform-none font-size-0-98rem opacity-35-to-1-on-hover hide-arrow"
                    >
                      Tell this owner
                    </div>

                    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">
                      <a
                        appHrefNone
                        class="dropdown-item flex-content-vc"
                        (click)="shareRestaurant('twitter')"
                      >
                        <span class="flex-fill text-white">Tweet</span>

                        <span class="icon-v2 twitter-white scale-08"></span>
                      </a>

                      <a
                        appHrefNone
                        class="dropdown-item flex-content-vc"
                        (click)="shareRestaurant('facebook')"
                      >
                        <span class="flex-fill text-white">Post</span>

                        <span class="icon-v2 facebook-white scale-08"></span>
                      </a>

                      <a appHrefNone class="dropdown-item flex-content-vc" (click)="copyLink()">
                        <span class="flex-fill text-white">Copy Link</span>

                        <span class="icon-v2 chain-white scale-08"></span>
                      </a>
                    </div>
                  </div>

                  <div
                    class="text-white text-transform-none cursor-pointer font-size-0-98rem opacity-35-to-1-on-hover"
                    (click)="onOwnVerificationClicked()"
                  >
                    Get owner access
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          [ngClass]="{
            'backdrop-filter-brightness-0-15': !sidenavHover,
            'backdrop-filter-brightness-1': sidenavHover,
            'cursor-pointer': owner
          }"
          class="right-content-container h-100 d-flex flex-column text-white"
          (click)="owner = false"
        >
          <div class="m-auto"></div>

          <div
            *ngIf="!sidenavHover"
            class="d-flex flex-content-hc w-100 font-size-0-98rem font-weight-400 opacity-05-to-1-on-hover mb-3"
          >
            {{ restaurant?.nationalPhoneNumber | mask: '(000) 000-0000' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-desktop-modal-sidenav
  *ngIf="restaurant"
  [restaurant]="restaurant"
  [basket]="basket"
  [showOwnershipDetails]="owner"
  [basketChange]="basketClick"
  (instagramClicked)="onInstagramLinkClick()"
  (mmmmClicked)="onMmmmClick()"
  (videoMenuClicked)="onVideoMenuClick()"
  (instagramMenuClicked)="onInstagramMenuClick()"
  (ownerClick)="owner = $event"
  (basketChangeClick)="basketClick = $event"
  (sidenavHover)="sidenavHovered($event)"
  (click)="nextSlide()"
></app-desktop-modal-sidenav>

<div
  appModalCloseOnClickPrevent
  *ngIf="modalArrows && hasPrev && restaurant"
  ngbTooltip="Try keyboard arrows!"
  class="btn btn-round arrow-bar left-n3rem d-flex align-items-center cursor-pointer justify-content-center z-index-10"
  (click)="changeRestaurantToPrevious(restaurant)"
  (mouseover)="arrowHover = true"
  (mouseleave)="arrowHover = false"
>
  <div
    [ngClass]="{'opacity-1': arrowHover, 'opacity-40':!arrowHover}"
    class="icon-v2 arrow-forward-white side-arrow-right cursor-pointer"
  ></div>
</div>

<div
  appModalCloseOnClickPrevent
  *ngIf="modalArrows && hasNext && restaurant"
  class="btn btn-round arrow-bar right-n3rem d-flex align-items-center cursor-pointer justify-content-center z-index-10"
  (click)="changeRestaurantToNext(restaurant)"
  (mouseover)="arrowHover = true"
  (mouseleave)="arrowHover = false"
>
  <div
    [ngClass]="{'opacity-1': arrowHover, 'opacity-40':!arrowHover}"
    class="icon-v2 arrow-forward-white rotate-180 side-arrow-right cursor-pointer"
  ></div>
</div>

<div class="left-scroll-area z-index-0 cursor-pointer" (click)="closeModal()"></div>

<div class="right-scroll-area z-index-0 cursor-pointer" (click)="closeModal()"></div>

<ng-template #videoIcon>
  <app-div-rectangle>
    <div class="bg-dark-on-modal text-white">
      <div class="pt-2rem opacity-05-to-1-on-hover cursor-pointer flex-content-hc" (click)="onMMMMIconClick()">
        Link video
      </div>
    </div>
  </app-div-rectangle>
</ng-template>

<ng-container *ngIf="!restaurantReady">
<!--  TODO need to add skeleton loader -->
</ng-container>
