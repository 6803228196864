<ng-container *ngIf="isMobile">
  <div class="w-100 h-100">
    <div class="row mt-5">
      <div class="col-search col-3"></div>

      <div class="col-9 px-1">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '68%',
            'height': '210px',
            'border-radius': '50%',
            'background-color': '#ffffff20'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <div class="row pt-3">
      <div class="col-search col-4"></div>

      <div class="col-8 pl-2">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '50%',
            'height': '20px',
            'border-radius': '1rem',
            'background-color': '#ffffff20'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-search col-3"></div>

      <div class="col-9">
        <ngx-skeleton-loader
          count="3"
          animation="progress-dark"
          [theme]="{
            'width': '35px',
            'height': '35px',
            'border-radius': '50%',
            'padding-left': '0',
            'margin-right': '2.2rem',
            'background-color': '#ffffff20'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <div class="row pt-1" *ngFor="let number of [0,1,2]">
      <div class="col-search col-2"></div>

      <div class="col-10 pl-2">
        <ngx-skeleton-loader
          count="1"
          animation="progress-dark"
          [theme]="{
            'width': '78%',
            'height': '20px',
            'border-radius': '1rem',
            'background-color': '#ffffff20'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>

    <div class="row row-search mt-2rem">
      <ng-container *ngFor="let number of [0,1]">
        <div class="col-search col-6 mb-1">
          <app-div-rectangle>
            <ngx-skeleton-loader
              count="1"
              animation="progress-dark"
              [theme]="{
              'width': '99.5%',
              'height': '99.5%',
              'border-radius': '0',
              'background-color': '#ffffff20'
            }"
            ></ngx-skeleton-loader>
          </app-div-rectangle>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isMobile">
  <div class="restaurant-desktop-main flex-fill w-100 h-100">
    <div class="restaurant-desktop-header d-flex">
      <div class="max-width-header px-0">
        <div class="card card-user pb-2rem content card-box-shadow bg-transparent">
          <div class="row">
            <div class="col-4 user flex-content-hc">
              <div class="mt-2rem rounded-circle">
                <ngx-skeleton-loader
                  count="1"
                  animation="progress-dark"
                  [theme]="{
                    'height': '255px',
                    'width': '255px',
                    'border-radius': '50%',
                    'background-color': '#ffffff20'
                  }"
                ></ngx-skeleton-loader>
              </div>
            </div>

            <div class="col-4">
              <div class="row pt-5 pb-2">
                <div class="col-search col-3"></div>

                <div class="col-9 pl-2 pb-3">
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress-dark"
                    [theme]="{
                      'width': '180px',
                      'height': '40px',
                      'border-radius': '1rem',
                      'background-color': '#ffffff20'
                    }"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="row pb-2">
                <div class="col-search col-3"></div>

                <div class="col-9 pb-3">
                  <ngx-skeleton-loader
                    count="3"
                    animation="progress-dark"
                    [theme]="{
                      'width': '35px',
                      'height': '35px',
                      'border-radius': '50%',
                      'padding-left': '0',
                      'margin-right': '2.2rem',
                      'background-color': '#ffffff20'
                    }"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="row">
                <div class="col-search col-1"></div>

                <div class="col-10 p-0">
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress-dark"
                    [theme]="{
                      'width': '100%',
                      'height': '50px',
                      'background-color': '#ffffff20',
                      'border-radius': '1rem'
                    }"
                  ></ngx-skeleton-loader>
                </div>

                <div class="col-search col-1"></div>
              </div>
            </div>

            <div class="col-4">
              <div class="flex-content-hc flex-content-hc mt-5">
                <span class="icon-v2 mmmm-2x-red scale-1_15"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="restaurant-desktop-content bg-white d-flex">
      <div class="max-width-feeds px-0">
        <div class="card card-user content card-box-shadow bg-transparent">
          <div class="row row-search mt-1-7rem">
            <ng-container *ngFor="let number of [0,1,2]">
              <div class="col-search col-4">
                <app-div-rectangle>
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress-dark"
                    [theme]="{
                      'width': '99.5%',
                      'height': '99.5%',
                      'border-radius': '0',
                      'background-color': '#ffffff20'
                    }"
                  ></ngx-skeleton-loader>
                </app-div-rectangle>
              </div>
            </ng-container>
          </div>

          <div class="row row-search mt-1-7rem">
            <ng-container *ngFor="let number of [0,1,2]">
              <div class="col-search col-4 mb-1">
                <app-div-rectangle>
                  <ngx-skeleton-loader
                    count="1"
                    animation="progress-dark"
                    [theme]="{
                      'width': '99.5%',
                      'height': '99.5%',
                      'border-radius': '0',
                      'background-color': '#ffffff20'
                    }"
                  ></ngx-skeleton-loader>
                </app-div-rectangle>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
