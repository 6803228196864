import { Exclude, Expose, Transform } from 'class-transformer';

@Exclude()
export class Place {
  @Expose()
  @Transform(value => value.split(', USA').join(''), {toClassOnly: true})
  description: string;

  @Expose({name: 'place_id'})
  placeId: string;
}
