<div
  *ngIf="!isLoginModalOpen && !isSubscribeModalOpen && !isRestaurantModalOpen && !isFavoriteModalOpen && !isVideoModalOpen"
  class="search-desktop"
>
  <div
    [ngClass]="{'search-desktop-background-active': !isSidenavLeftOrRightMouseOver || isSearching}"
    class="search-desktop-background"
  ></div>

  <div class="d-flex search-desktop-content" (scroll)="onScroll()">
    <div *ngIf="isSidenavLeftOrRightMouseOver && !isSearchModalOpen" class="search-desktop-text pt-5 d-flex">
      <h1 class="text-white">Every restaurant as a photo journal</h1>
    </div>

    <app-search-desktop-loader *ngIf="isSearching" class="w-100"></app-search-desktop-loader>

    <ng-container *ngIf="!isSearching">
      <div class="search-desktop-main flex-fill">
        <ng-container *ngIf="searchResult.groupsFiltered?.length > 0">
          <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionFirst = first;">
            <ng-container
              *ngFor="let typeCategorySection of group.sections; let typeCategorySectionFirst = first;"
            >
              <div
                #exploreEmpty
                *ngIf="typeCategorySection.hasChain || typeCategorySection.hasRestaurants"
                [attr.data-keys]="typeCategorySection.keysString"
                [ngClass]="{
                  'opacity-0': isSidenavLeftOrRightMouseOver,
                  'search-desktop-category-section-first': sectionFirst && typeCategorySectionFirst,
                  'search-desktop-category-section-example': typeCategorySection.isExample
                }"
                id="search-desktop-category-section-{{typeCategorySection.keysString}}"
                class="search-desktop-category-section"
              >
                <div
                  class="category-opacity flex-content-vc-hr text-uppercase text-white font-weight-600 cursor-pointer"
                  (click)="openDividerModal(typeCategorySection)"
                >
                  {{ typeCategorySection.title }}
                </div>

                <div
                  *ngFor="let typeCategory of typeCategorySection.typeCategories;"
                  class="search-desktop-category"
                >
                  <ng-container *ngFor="let restaurant of typeCategory.restaurants;">
                    <div class="search-desktop-restaurant" id="restaurant-{{restaurant.id}}">
                      <div class="flex-content-vc-hr">
                        <div class="search-desktop-restaurant-header d-flex">
                          <div class="flex-fill">
                            <a
                              appHrefNone
                              class="search-desktop-restaurant-name"
                              (click)="openRestaurantModal(restaurant, typeCategorySection)"
                              (mouseenter)="restaurant.hover = true"
                              (mouseleave)="restaurant.hover = false"
                            >
                              {{ restaurant.display | abbreviate:true:'' }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div
                  *ngIf="!typeCategorySection.isExample"
                  class="d-flex justify-content-end w-100 search-desktop-restaurant search-desktop-restaurant-header search-desktop-category"
                >
                  <a
                    [ngClass]="{
                      'opacity-35': !typeCategorySection.hasChain,
                      'cursor-pointer': typeCategorySection.hasChain
                    }"
                    (click)="openChainDesktopModal(typeCategorySection)"
                    class="icon-v2 chains-new"
                  ></a>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div
            [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver || !isLocalitySelected}"
            class="d-flex justify-content-end align-items-end mt-2rem"
          >
            <span
              class="cursor-pointer font-size-1-85rem text-white opacity-35-to-1-on-hover"
              (click)="addRestaurantDesktop()"
            >
              Add restaurant
            </span>
          </div>
        </ng-container>
      </div>

      <div class="search-desktop-sidebar text-white">
        <div class="search-desktop-controls d-block">
          <div class="search-desktop-controls-content d-flex">
            <div class="search-desktop-restaurants">
              <ng-container *ngIf="searchResult.groupsFiltered && isCitySelected">
                <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionI = index;">
                  <div
                    class="search-desktop-section search-desktop-section-margin"
                    [ngClass]="{'search-desktop-section-margin': sectionI != 0}"
                  >
                    <ng-container *ngFor="let typeCategorySection of group.sections; let i = index;">
                      <div *ngIf="!typeCategorySection.isExample" class="search-desktop-section-title">
                        <button
                          [ngClass]="{
                            'btn-active': sectionActive && sectionActive == typeCategorySection,
                            'opacity-0': isSidenavLeftOrRightMouseOver || !isLocalitySelected
                          }"
                          [disabled]="!typeCategorySection.hasRestaurants || !isLocalitySelected"
                          class="btn btn-dark btn-primary-shadow md-btn-flat text-transform-none font-weight-400"
                          (click)="typeCategorySectionSelect(typeCategorySection)"
                        >
                          {{ typeCategorySection.title }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <div
              class="d-flex flex-column"
              [ngClass]="{
                'opacity-0': isSidenavLeftOrRightMouseOver,
                'search-desktop-filters': isLocalitySelected
              }"
            >
              <a
                appHrefNone
                [ngClass]="{
                  'mmmm-2x-red': !sectionActive || isSidenavLeftOrRightMouseOver || logoMouseOver,
                  'mmmm-2x-gray': !(!sectionActive || isSidenavLeftOrRightMouseOver || logoMouseOver)
                }"
                class="icon-v2 ml-n2px"
                (mouseover)="logoMouseOver = true"
                (mouseleave)="logoMouseOver = false"
                (click)="logoClick()"
              ></a>

              <div
                [ngClass]="{'opacity-0': isSidenavLeftOrRightMouseOver}"
                class="search-desktop-filters-city mt-3"
              >
                <app-search-filters
                  [country]="country"
                  [searchParams]="searchParams"
                  [isHovered]="isHovered"
                  [preview]="preview"
                  [hideTemporary]="true"
                  [position]="searchFiltersPositionUnderLogo"
                  [isSearching]="isSearching"
                  (citySelectedChange)="onCitySelected($event)"
                  (previewChange)="onPreviewChange()"
                  (locationDropdownChange)="onLocationDropdownChange()"
                  (localitySelectedChange)="onLocalitySelectedChange($event)"
                ></app-search-filters>

                <app-distance-filter
                  *ngIf="searchParams.geoLocation"
                  [searchParams]="searchParams"
                  [searchResult]="searchResult"
                ></app-distance-filter>
              </div>

              <div
                *ngIf="false"
                [ngClass]="{'visibility-hidden': isCitySelected}"
                class="search-desktop-add-share"
              >
                <a
                  appHrefNone
                  appOpacityFullOnHover
                  class="d-inline-block text-white opacity-50 mb-2"
                  (click)="addRestaurantDesktop()"
                >
                  Add
                </a>

                <br>

                <div ngbDropdown>
                  <a
                    appHrefNone
                    ngbDropdownToggle
                    appOpacityFullOnHover
                    class="d-inline-block text-white opacity-50 hide-arrow"
                  >
                    Share
                  </a>

                  <div ngbDropdownMenu>
                    <a
                      href="https://instagram.com/mmmm.menu"
                      target="_blank"
                      class="dropdown-item"
                    >
                      Instagram
                    </a>

                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('twitter')">
                      Twitter
                    </a>

                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('facebook')">
                      Facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #restaurantAndChainsAndDividerModalRef>
  <app-restaurant-modal
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.RESTAURANT"
    [typeCategorySection]="activeTypeCategorySection"
    [restaurantExplore]="clickedRestaurant"
    [modalArrows]="true"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-restaurant-modal>

  <app-chain-modal-desktop
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.CHAIN"
    [typeCategorySection]="activeTypeCategorySection"
    [cityName]="searchParams.locationName"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-chain-modal-desktop>

  <app-divider-modal
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.DIVIDER"
    [typeCategorySection]="activeTypeCategorySection"
    [cityName]="searchParams.locationName"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-divider-modal>
</ng-template>

<ng-template #favoriteDesktopModalRef>
  <app-favorites-list
    [cityName]="searchParams.locationName"
    [favoriteList]="true"
    [locationFavorites]="locationFavorites"
    [searchResult]="searchResult"
    (favoriteStatusChanged)="onFavoriteStatusChange()"
    (desktopRestaurantNameClick)="openRestaurantModal($event, activeTypeCategorySection)"
  ></app-favorites-list>
</ng-template>

<ng-template #addRestaurantModalRef>
  <app-restaurant-add-modal></app-restaurant-add-modal>
</ng-template>
